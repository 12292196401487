import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { CheckOutlined } from '@ant-design/icons';
import ScrollAnimation from 'react-animate-on-scroll';

const Services = () => {
    return(
         <div id="Services">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} >
                <h2> Sme spoločnosť zaoberajúca sa hlavne vnútroštátnou a medzinárodnou cestnou dopravou </h2>
                <Row>
                    <Col xs={24} md={12}>
                    <h3> Paletizovaný tovar <CheckOutlined /></h3>
                    <h3> Voľne ložený tovar <CheckOutlined /></h3>
                    <h3> Sypký tovar <CheckOutlined /></h3>
                    </Col>
                    <Col xs={24} md={12}>
                    <h3> Kusový tovar <CheckOutlined /></h3>
                    <h3> Práce s montážnou plošinou MP16 <CheckOutlined /></h3>
                    </Col>
                </Row>
            </ScrollAnimation>
         </div>
    );
};

export default Services;