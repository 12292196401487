
import DesktopNavbar from "./Navbar/DesktopNavbar";
import MobileNavbar from "./Navbar/MobileNavbar";
import Main from "./Main/Main";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";

const Homepage = () =>{

    let Navbar = <DesktopNavbar />

    if (window.innerWidth < 1000) {
        Navbar =  <MobileNavbar />  
    }

    return(
        <>
            {Navbar}
            <Main />
            <Services />
            <Contact />
            <Footer />
        </> 
    );
};

export default Homepage;