import { Carousel } from 'antd';

const Main = () => {
    return(
        <div id="MainContent">
            <Carousel autoplay dots>
            <div className="Carousel Cestna" >
                <h2 > Cestná nákladná doprava </h2>
            </div>
            <div className="Carousel Tovar" >
                <h2 > Preprava paletizovaných, sypkých, kusových tovarov </h2>
            </div>
            </Carousel>,
        </div>
    );
};

export default Main;