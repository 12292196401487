import Row from "antd/lib/row";
import Col from "antd/lib/col";
import ScrollAnimation from 'react-animate-on-scroll';

const Contact = () => {

    let dimmensionsOfMap = { width: "100%", height: "450px"}

    if (window.innerWidth < 1000) {
        dimmensionsOfMap =   { width: "80%", height: "350px"}
    } // simply if which show us the right navbar

    return(
        <Row id="Contact">
            <Col className="ContactInfo" xs={24} md={12}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} >

                    <h3> Kontaktné údaje </h3>
                    <p> 
                            MRVANTRANS, s.r.o. <br/>
                            Dolné Rakovce 1923/35 <br/>
                            IČO: 53127323  <br/>
                            IČ DPH: SK2121273165 <br />
                            039 01 Turčianske Teplice <br/>
                            E-mail: mrvan.lukas@live.com <br/>
                            <br/>
                            Telefónny kontakt: <br />
                            Ján Mrváň:    <a style={{ color: "#fff"}}  href="tel:00421917357975"> +421 917 357 975 </a> 
                    </p>
                </ScrollAnimation>
            </Col>
            <Col className="Map" xs={24} md={12}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.2147697763803!2d18.857299215674914!3d48.873182179288946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714e21fb2949c0f%3A0x715ee5d192df9422!2sDoln%C3%A9%20Rakovce%2C%20039%2001%20Tur%C4%8Dianske%20Teplice!5e0!3m2!1ssk!2ssk!4v1623445474858!5m2!1ssk!2ssk" title="Mapa" width={dimmensionsOfMap.width} height={dimmensionsOfMap.height} style={{border: "0", padding: "20px", borderRadius: "40px"}} allowFullScreen="" loading="lazy"></iframe>
            </Col>
        </Row>
    );
};

export default Contact;