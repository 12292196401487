import Row from "antd/lib/row";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/logo3.svg";
import { PhoneOutlined, DownloadOutlined } from '@ant-design/icons';

import Pdf from "../../../assets/files/prepravnyPoriadok.pdf";

const DesktopNavbar = (props) => {
    return(
        <Row className="Navbar">
            <Link to="MainContent" smooth={true} offset={-100} duration={1000} > <Logo className="LogoNavbar" /> </Link>
            <h2> Vnútroštátna a medzinárodná cestná doprava </h2>
            <Row className="Navigation">
                <Link to="Services" smooth={true} offset={0} duration={1000} > Naše služby </Link>
                <NavLink to="/gallery" > Galéria </NavLink>
                <Link to="Contact" smooth={true} offset={-100} duration={1000} > Kontakt </Link>
                <a href={Pdf} download > Prepravný poriadok <DownloadOutlined /> </a>
                <a href="tel:00421905357970"> <PhoneOutlined /> </a>
            </Row>
        </Row>
    );
};

export default DesktopNavbar;