import { Link } from "react-scroll";
import { PhoneOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";


import Pdf from "../../../assets/files/prepravnyPoriadok.pdf";

const Modal = ({clicked, openGalery}) => {
    return(
        <div className="Modal">
            <Link onClick={clicked} to="Services" smooth={true} offset={-100} duration={1000} > Naše služby </Link>
            <Link onClick={clicked} to="Contact" smooth={true} offset={-100} duration={1000} > Kontakt </Link>
            <a onClick={clicked} href={Pdf} download > Prepravný poriadok </a>
            <NavLink to="/gallery" > Galéria </NavLink>
            <a onClick={clicked} href="tel:00421917357975"> <PhoneOutlined /> </a>        
        </div>
    );
};

export default Modal;