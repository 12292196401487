import { ReactComponent as Logo } from "../../../assets/images/logo3.svg";

const Footer = () => {
    return(
        <div className="Footer">
            <Logo className="LogoNavbar" />
            <p className="Copyright"> © 2021 MRVANTRANS, s.r.o. </p>
        </div>
    );
};

export default Footer;
