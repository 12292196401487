import { Route } from "react-router-dom";

import Homepage from "./components/Homepage/Homepage";
import Gallery from "./components/Gallery/Gallery";

const App = () => {

  return(
    <div className="App">
      <Route path="/" exact component={Homepage} />
      <Route path="/gallery" exact component={Gallery} />
    </div>

  );
};

export default App;
