import Modal from "./Modal";
import { useState } from "react";

import { Link } from "react-scroll";
import { ReactComponent as Logo } from "../../../assets/images/logo3.svg";
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

const MobileNavbar = ({openGalery}) => {

    const [ menuOpen, setMenuOpen ] = useState(false);

    const menuOpenHandler = () => {
        setMenuOpen(prevState => !prevState)
    }

    let menu = <MenuOutlined className="MobileIcon" onClick={menuOpenHandler} />
    if(menuOpen) {
        menu = 
        <>
            <CloseOutlined className="MobileIcon"  onClick={menuOpenHandler} />
            <Modal clicked={menuOpenHandler} />
        </>
    }

    return(
        <div className="MobileNavbar">
            <Link onClick={menuOpenHandler} to="MainContent" smooth={true} offset={-100} duration={1000} > <Logo className="MobileLogo" /> </Link>
            {menu}
        </div>
    );
};

export default MobileNavbar;