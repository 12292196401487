import ImageGallery from 'react-image-gallery';
import { NavLink } from "react-router-dom";

import Photo1 from "../../assets/images/kamion1.jpg"
import Photo2 from "../../assets/images/kamion2.jpg"
import Photo3 from "../../assets/images/kamion3.jpg"
import Photo4 from "../../assets/images/kamion4.jpg"
import Photo5 from "../../assets/images/kamion5.jpg"
import Photo6 from "../../assets/images/kamion6.jpg"
import Photo7 from "../../assets/images/kamion7.jpg"
import Photo8 from "../../assets/images/kamion8.jpg"
import Photo9 from "../../assets/images/kamion9.jpg"


const images = [
  {
    original: Photo1,
    thumbnail: Photo1,
  },
  {
    original: Photo2,
    thumbnail: Photo2,
  },
  {
    original: Photo3,
    thumbnail: Photo3,
  },  
  {
    original: Photo4,
    thumbnail: Photo4,
  },  
  {
    original: Photo5,
    thumbnail: Photo5,
  },  
  {
    original: Photo6,
    thumbnail: Photo6,
  },  
  {
    original: Photo7,
    thumbnail: Photo7,
  },  
  {
    original: Photo8,
    thumbnail: Photo8,
  },
  {
    original: Photo9,
    thumbnail: Photo9,
  }
];

const Gallery = () => {
  return(
    <div className="Gallery" > 
      <NavLink to="/" > X </NavLink>
      <ImageGallery showBullets={true} lazyLoad={true} items={images} /> 
    </div>
  );
};

export default Gallery;
